import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import CustomFetch from '../components/fetch';
import CustomMessage from '../components/message';
import parse from 'html-react-parser';
import validator from 'validator';
import { Stop } from 'akar-icons';

const ProfilePage = () => {
    const [data, setData] = useState({
        id: '',
        name: '',
        password: '',
    });
    const [showError, setShowError] = useState(false);
    const [messageSetting, setMessageSetting] = useState({
        showMessage: false,
        messageClass: 'dialog dialog--error',
        messageContent: '',
    });

    const handleChange = (e) => {
        if (e) {
            setData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));
        }
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();

            let _error = '';

            if (data.name === '') {
                _error = 'Please enter your name<br />';
                setShowError(true);
            }

            if (data.password !== '') {
                if (validator.isStrongPassword(data.password) === false) {
                    _error =
                        'The password is too weak: min length: 8, min of uppercase: 1, min of lowercase: 1, min of numbers: 1 and min of symbols: 1.';
                }
            }

            if (_error === '') {
                CustomFetch(
                    `users/${data.id}`,
                    'PUT',
                    data,
                    function (result) {
                        if (result.error === '') {
                            CustomFetch(
                                'checkAuthStatus',
                                'POST',
                                {},
                                function (result) {
                                    setData((prevState) => ({
                                        ...prevState,
                                        id: result.user.id,
                                        name: result.user.name,
                                        password: '',
                                    }));

                                    setMessageSetting((prevState) => ({
                                        ...prevState,
                                        showMessage: true,
                                        messageClass: 'dialog dialog--success',
                                        messageContent:
                                            'Your profile has been updated.',
                                    }));
                                },
                                function (error) {
                                    if (
                                        error.includes(
                                            'You are not authenticated'
                                        )
                                    ) {
                                        localStorage.clear();
                                        navigate('/login');
                                    }
                                }
                            );

                            setShowError(false);
                        } else {
                            setMessageSetting((prevState) => ({
                                ...prevState,
                                showMessage: true,
                                messageClass: 'dialog dialog--error',
                                messageContent: parse(_error),
                            }));
                        }
                    },
                    function (error) {
                        if (error.includes('You are not authenticated')) {
                            localStorage.clear();
                            navigate('/login');
                        }
                    }
                );
            } else {
                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: true,
                    messageClass: 'dialog dialog--error',
                    messageContent: parse(_error),
                }));
            }
        }
    };

    useEffect(() => {
        CustomFetch(
            'checkAuthStatus',
            'POST',
            {},
            function (result) {
                setData((prevState) => ({
                    ...prevState,
                    id: result.user.id,
                    name: result.user.name,
                    password: '',
                }));
            },
            function (error) {
                if (error.includes('You are not authenticated')) {
                    localStorage.clear();
                    navigate('/login');
                }
            }
        );
    }, []);

    return (
        <Layout title="Profile">
            <Seo title="Profile" description="Dawn Projects Ticket System" />
            <CustomMessage data={messageSetting} />
            <form onSubmit={handleSubmit}>
                <div className="wrap">
                    <div className="ticketform">
                        {showError === true ? (
                            <div className="formitem ticketerror">
                                <Stop size={24} />
                                <span>
                                    Error! Please check the required fields.
                                </span>
                            </div>
                        ) : null}
                        <div
                            className="formitem fw"
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <label className="fi__label">
                                <input
                                    name="name"
                                    onChange={handleChange}
                                    value={data.name}
                                    type="text"
                                    placeholder=" "
                                />
                                <span className="fi__span">
                                    Name <small>Required</small>
                                </span>
                            </label>
                        </div>
                        <div
                            className="formitem fw"
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <label className="fi__label">
                                <input
                                    name="password"
                                    onChange={handleChange}
                                    value={data.password}
                                    type="password"
                                    placeholder=" "
                                />
                                <span className="fi__span">
                                    Password <small>Required</small>
                                </span>
                            </label>
                        </div>
                        <div
                            className="formitem fw"
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <button type="submit" className="btn">
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Layout>
    );
};

export default ProfilePage;
